import React from 'react';
import PropTypes from 'prop-types';
import GroupEnquiriesTable from '@components/air/GroupEnquiriesTable';

function GroupEnquiriesPage() {
    return (
        <div>
            <h3>团队订单</h3>
            <GroupEnquiriesTable />
        </div>
    );
}

GroupEnquiriesPage.propTypes = {};

export default GroupEnquiriesPage;
