import { useState, useCallback, useRef } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';
import { set } from 'date-fns';

function useFlightOrder() {
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState({});
    const [order, setOrder] = useState({});
    const abortControllerRef = useRef(null);

    const fetchOrder = useCallback(async (orderNo) => {
        console.log('Fetching order:', orderNo);
        setFetching(true);
        try {
            abortControllerRef.current = new AbortController();
            const response = await fetch(`${config.apiURL2}/flights/orders/${orderNo}`, {
                method: 'GET',
                headers: { Authorization: `Bearer ${GetToken()}` },
                signal: abortControllerRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while fetching flight order');
            }
            const data = await response.json();
            setData(data);
            console.log('order', data.order);
            setOrder(data.order);
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetching flight order:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { fetching, data, order, fetchOrder, abort };
}

export default useFlightOrder;
