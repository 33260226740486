import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useCode2Name from '../../../hooks/flight/useCode2Name';

function AirportName({ code }) {
    const { fethcing, nameCN, fetchName, abort } = useCode2Name();
    useEffect(() => {
        if (code) {
            fetchName(code);
        }
        return () => {
            abort();
        };
    }, [code]);
    return <>{nameCN}</>;
}

AirportName.propTypes = {
    code: PropTypes.string.isRequired,
};

export default React.memo(AirportName);
