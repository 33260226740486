import { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useGroupEnquiries(props) {
    const [fetching, setFetching] = useState(false);
    const [enquiries, setEnquiries] = useState([]);
    const [total, setTotal] = useState(0);
    const abortControllerRef = useRef(null);

    const fetchEnquiries = useCallback(async ({ page, pageSize } = {}) => {
        const offset = (page - 1) * pageSize;
        const limit = pageSize;
        setFetching(true);
        abortControllerRef.current = new AbortController();
        // console.log('limit', limit, 'offset', offset);
        try {
            const response = await fetch(`${config.apiURL2}/group-travel/enquiries?limit=${limit}&offset=${offset}`, {
                method: 'GET',
                headers: { Authorization: `Bearer ${GetToken()}` },
                signal: abortControllerRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while fetch enquiries');
            }
            const result = await response.json();
            setEnquiries(result.enquiries);
            setTotal(result.total);
            return result;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetch group enquiries:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { fetching, enquiries, total, abort, fetchEnquiries };
}

useGroupEnquiries.propTypes = {};

export default useGroupEnquiries;
