import { useState, useCallback, useRef } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';
import axios from 'axios';

function useGroupEnquiryCreate(props) {
    const [submitting, setSubmitting] = useState(false);
    // progress
    const [precent, setPercent] = useState(0);
    const abortControllerRef = useRef(null);

    //Use axios over fetch API, because of progress tracking and timeout.
    const handleEnquiryCreate = useCallback(async (formData) => {
        setSubmitting(true);
        abortControllerRef.current = new AbortController();
        try {
            // Simulating an API call
            // await new Promise((resolve) => setTimeout(resolve, 1000));
            const response = await axios.post(`${config.apiURL2}/group-travel/enquiry`, formData, {
                headers: { Authorization: `Bearer ${GetToken()}` },
                signal: abortControllerRef.current.signal,
                onUploadProgress: (progressEvent) => {
                    const total = progressEvent.total;
                    let percentCompleted = Math.floor((progressEvent.loaded * 100) / total);
                    setPercent(percentCompleted);
                },
            });
            if (response.status < 200 || response.status >= 300) {
                const data = response.data;
                throw new Error(data.error || 'An error occurred while create a group enquiry');
            }
            const data = response.data;
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error create a group enquiry:', error);
            }
            throw error;
        } finally {
            setSubmitting(false);
        }
    }, []);

    handleEnquiryCreate.propTypes = {
        // makee sure the prop is an instance of FormData
        formData: (props, propName, componentName) => {
            if (!(props[propName] instanceof FormData)) {
                return new Error(
                    `Invalid prop ${propName} supplied to ${componentName}. Expected instance of FormData.`
                );
            }
        },
    };

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { submitting, precent, handleEnquiryCreate, abort };
}

useGroupEnquiryCreate.propTypes = {};

export default useGroupEnquiryCreate;
