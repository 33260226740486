import { defaultQueryReq } from '.';

const key = 'group_flight_enquiries_query_params';

export const GetQuery = () => {
    const query = sessionStorage.getItem(key);
    return query ? JSON.parse(query) : defaultQueryReq;
};

export const SaveQuery = (query) => {
    sessionStorage.setItem(key, JSON.stringify(query));
};
