import React from 'react';
import PropTypes from 'prop-types';
import { Button, App } from 'antd';
import useRefreshFlightOrder from '../../../hooks/flight/useRefreshFlightOrder';

function Refresh({ orderNo }) {
    const { handleRefresh, refreshing } = useRefreshFlightOrder();
    const { message } = App.useApp();

    if (!orderNo) {
        return null;
    }
    const onRefresh = (orderNo) => {
        console.log(`Refreshing order: ${orderNo}`);
        handleRefresh(orderNo)
            .then((data) => {
                const reply = data.reply;
                if (reply) {
                    message?.success(reply);
                }
            })
            .catch((error) => {
                console.error('Error refreshing flight order:', error);
                message?.error(error.message);
            });
    };

    return (
        <Button
            type="link"
            loading={refreshing}
            style={{ padding: 0 }}
            onClick={() => onRefresh(orderNo)}
            aria-label={`刷新订单 ${orderNo}`}
        >
            刷新
        </Button>
    );
}

Refresh.propTypes = {
    orderNo: PropTypes.string.isRequired,
};

export default Refresh;
