// Centralized time slot definitions
export const TIME_SLOTS = {
    EARLY_MORNING: { value: '5,8', label: '清晨 (5:00 AM - 8:00 AM)' },
    MORNING: { value: '8,12', label: '上午 (8:00 AM - 12:00 PM)' },
    NOON: { value: '12,15', label: '中午 (12:00 PM - 3:00 PM)' },
    AFTERNOON: { value: '15,18', label: '下午 (3:00 PM - 6:00 PM)' },
    EVENING: { value: '18,21', label: '晚上 (6:00 PM - 9:00 PM)' },
    NIGHT: { value: '21,24', label: '夜间 (9:00 PM - 12:00 AM)' },
    LATE_NIGHT: { value: '0,5', label: '凌晨 (12:00 AM - 5:00 AM)' },
};

// Centralized stop type definitions
export const STOP_TYPES = {
    NONSTOP: { value: 'nonstop', label: '直飞' },
    WITH_STOPS: { value: 'withStops', label: '中转' },
};
