import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Routing from './Routing';
import styles from './RoutingsList.module.css';
import Filters, { FilterKey } from './components/Filters';
import { STOP_TYPES } from './constants';
import { isInTimeSlot } from './utils';

function RoutingsList({ routings = [], request = {} }) {
    // processed routing data
    const [data, setData] = useState([]);
    // loaded items
    const [loadedItems, setLoadedItems] = useState([]);
    const [filterData, setFilterData] = useState({});
    const loaderRef = useRef(null);
    const ITEMS_PER_PAGE = 15;

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && data.length > loadedItems.length) {
                    loadMoreItems();
                }
            },
            { threshold: 0.1 }
        );

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [data, loadedItems]);

    const loadMoreItems = useCallback(() => {
        const nextItems = data.slice(loadedItems.length, loadedItems.length + ITEMS_PER_PAGE);
        setLoadedItems((prevItems) => [...prevItems, ...nextItems]);
    }, [data, loadedItems]);

    useEffect(() => {
        applyFilters(routings);
        console.log('routing', routings.slice(0, 1));
    }, [filterData]);

    const airlineOptions = useMemo(() => {
        const airlines = new Set();
        routings.forEach((route) => {
            route.fromSegments.forEach((seg) => {
                airlines.add(seg.operatingCarrier);
            });
            if (route.retSegments) {
                route.retSegments.forEach((seg) => {
                    airlines.add(seg.operatingCarrier);
                });
            }
        });
        return Array.from(airlines).map((airline) => ({ label: airline, value: airline }));
    }, [routings]);

    const { depAirportOptions, arrAirportOptions } = useMemo(() => {
        const departureAirports = new Set();
        const arrivalAirports = new Set();
        routings.forEach((route) => {
            if (route.fromSegments?.[0]?.depAirportCode) {
                departureAirports.add(route.fromSegments[0].depAirportCode);
            }
            if (route.fromSegments?.length > 0) {
                const lastFromSegment = route.fromSegments[route.fromSegments.length - 1];
                if (lastFromSegment.arrAirportCode) {
                    arrivalAirports.add(lastFromSegment.arrAirportCode);
                }
            }
            if (route.retSegments?.[0]?.depAirportCode) {
                departureAirports.add(route.retSegments[0].depAirportCode);
            }
            if (route.retSegments?.length > 0) {
                const lastRetSegment = route.retSegments[route.retSegments.length - 1];
                if (lastRetSegment.arrAirportCode) {
                    arrivalAirports.add(lastRetSegment.arrAirportCode);
                }
            }
        });
        return {
            depAirportOptions: Array.from(departureAirports).map((code) => ({ label: code, value: code })),
            arrAirportOptions: Array.from(arrivalAirports).map((code) => ({ label: code, value: code })),
        };
    }, [routings]);

    const stopsOptions = useMemo(() => {
        const stopAirports = new Set();
        routings.forEach((route) => {
            if (route.fromSegments?.length > 1) {
                route.fromSegments.forEach((seg) => {
                    stopAirports.add(seg.depAirportCode);
                    stopAirports.add(seg.arrAirportCode);
                });
                // remove first and last segment's depAirport and arrAirport
                const firstSegment = route.fromSegments[0];
                const lastSegment = route.fromSegments[route.fromSegments.length - 1];
                stopAirports.delete(firstSegment.depAirportCode);
                stopAirports.delete(lastSegment.arrAirportCode);
            }

            if (route.retSegments?.length > 1) {
                route.retSegments.forEach((seg) => {
                    stopAirports.add(seg.depAirportCode);
                    stopAirports.add(seg.arrAirportCode);
                });
                // remove first and last segment's depAirport and arrAirport
                const firstSegment = route.retSegments[0];
                const lastSegment = route.retSegments[route.retSegments.length - 1];
                stopAirports.delete(firstSegment.depAirportCode);
                stopAirports.delete(lastSegment.arrAirportCode);
            }
        });
        return Array.from(stopAirports).map((code) => ({ label: code, value: code }));
    }, [routings]);

    const applyFilters = useCallback(
        (routings) => {
            console.log('apply filter', filterData);
            // filter routings according to filterData
            let filteredData = [...routings];
            console.log('filteredData length original', filteredData.length);
            filteredData = filteredData.filter((route) => {
                // Non-stops/stops filter
                if (filterData[FilterKey.STOP_TYPE]) {
                    // Non-stop
                    if (filterData[FilterKey.STOP_TYPE] === STOP_TYPES.NONSTOP.value) {
                        if (route.fromSegments.length > 1) {
                            return false;
                        }
                    }
                }
                return true;
            });

            // Stops filter
            if (filterData[FilterKey.STOPS] && filterData[FilterKey.STOP_TYPE] === STOP_TYPES.WITH_STOPS.value) {
                filteredData = filteredData.filter((route) => {
                    const stops = filterData[FilterKey.STOPS];
                    // if stops is an array (mulitple airport codes)
                    let found = false;
                    if (Array.isArray(stops)) {
                        if (route.fromSegments.length > 1) {
                            route.fromSegments.forEach((seg) => {
                                if (stops.includes(seg.depAirportCode) || stops.includes(seg.arrAirportCode)) {
                                    found = true;
                                    return;
                                }
                            });
                        }
                    } else if (typeof stops === 'string') {
                        // if stops is a string (airport code)
                        if (route.fromSegments.length > 1) {
                            route.fromSegments.forEach((seg) => {
                                if (seg.depAirportCode === stops || seg.arrAirportCode === stops) {
                                    console.log('stops match', stops, seg.depAirportCode, seg.arrAirportCode);
                                    found = true;
                                    return;
                                }
                            });
                        }
                    } else {
                        //unknown type
                        console.error('Unknown type for stops filter', stops);
                    }
                    return found;
                });
            }
            // Airline filter
            if (filterData[FilterKey.AIRLINE]) {
                filteredData = filteredData.filter((route) => {
                    let found = false;
                    route.fromSegments.forEach((seg) => {
                        if (seg.operatingCarrier === filterData[FilterKey.AIRLINE]) {
                            found = true;
                        }
                    });
                    return found;
                });
            }
            // Departure time filter, value is in format "5,8", starting from 5:00 AM to 8:00 AM
            if (filterData[FilterKey.DEPARTURE_TIME]) {
                const value = filterData[FilterKey.DEPARTURE_TIME];
                // if value is an array (multiple time slots)
                if (Array.isArray(value)) {
                    //NOTE:  Not widely used, not implemented
                } else if (typeof value === 'string') {
                    // value is string
                    const [start, end] = value.split(',').map(Number);
                    filteredData = filteredData.filter((route) => {
                        const outboundMatch =
                            route.fromSegments?.length > 0 &&
                            isInTimeSlot(route.fromSegments[0]?.depTime, [start, end]);
                        const inboundMatch =
                            route.retSegments?.length > 0 && isInTimeSlot(route.retSegments[0]?.depTime, [start, end]);
                        return outboundMatch || inboundMatch;
                    });
                } else {
                    console.error('Unknown type for departure time filter', value);
                }
            }
            // Arrival time filter
            if (filterData[FilterKey.ARRIVAL_TIME]) {
                const value = filterData[FilterKey.ARRIVAL_TIME];
                // if value is an array (multiple time slots)
                if (Array.isArray(value)) {
                    //NOTE:  Not widely used, not implemented
                } else if (typeof value === 'string') {
                    // value is string
                    const [start, end] = value.split(',').map(Number);
                    filteredData = filteredData.filter((route) => {
                        const outboundMatch =
                            route.fromSegments?.length > 0 &&
                            isInTimeSlot(route.fromSegments[route.fromSegments.length - 1]?.arrTime, [start, end]);
                        const inboundMatch =
                            route.retSegments?.length > 0 &&
                            isInTimeSlot(route.retSegments[route.retSegments.length - 1]?.arrTime, [start, end]);
                        return outboundMatch || inboundMatch;
                    });
                } else {
                    console.error('Unknown type for arrival time filter', value);
                }
            }
            // Departure airport filter
            if (filterData[FilterKey.DEPARTURE]) {
                const value = filterData[FilterKey.DEPARTURE];
                if (Array.isArray(value)) {
                    //NOTE:  Not widely used, not implemented
                    console.error('not implemented for Array type');
                } else if (typeof value === 'string') {
                    filteredData = filteredData.filter((route) => {
                        const firstSegment = route.fromSegments[0];
                        if (firstSegment.depAirportCode === value) {
                            return true;
                        }
                    });
                } else {
                    console.error('Unknown type for deparue airport filter', value);
                }
            }
            // Arrival airport filter
            if (filterData[FilterKey.ARRIVAL]) {
                const value = filterData[FilterKey.ARRIVAL];
                if (Array.isArray(value)) {
                    //NOTE:  Not widely used, not implemented
                    console.error('not implemented for Array type');
                } else if (typeof value === 'string') {
                    filteredData = filteredData.filter((route) => {
                        const lastSegment = route.fromSegments[route.fromSegments.length - 1];
                        if (lastSegment.arrAirportCode === value) {
                            return true;
                        }
                    });
                } else {
                    console.error('Unknown type for arrial airport filter', value);
                }
            }
            console.log('filteredData length', filteredData.length);
            setData(filteredData);
            setLoadedItems(filteredData.slice(0, ITEMS_PER_PAGE));
        },
        [filterData, routings]
    );

    return (
        <div className={styles.container}>
            {routings.length > 0 && (
                <section className={styles.filter_section}>
                    <div className={styles.stat_container}>
                        已选择的筛选条件:&nbsp;<span className={styles.stat}>{data?.length}</span>个航班
                    </div>
                    <Filters
                        airlineOptions={airlineOptions}
                        stopsOptions={stopsOptions}
                        depAirportOptions={depAirportOptions}
                        arrAirportOptions={arrAirportOptions}
                        filterData={filterData}
                        setFilterData={setFilterData}
                    />
                </section>
            )}

            <div className={styles.routings_container}>
                {loadedItems.map((routing, index) => {
                    return (
                        <Routing
                            key={index}
                            routing={routing}
                            ref={index === routing.length - 1 ? lastItemRef : null}
                            request={request}
                        />
                    );
                })}
                {data.length > loadedItems.length && <div ref={loaderRef}>Loading more items...</div>}
            </div>
        </div>
    );
}

RoutingsList.propTypes = {
    routings: PropTypes.array,
    request: PropTypes.object,
};

export default RoutingsList;
