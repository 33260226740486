import { useCallback, useState } from 'react';
import { GetToken } from '@utils/storage';
import { useRef } from 'react';
import * as config from '@config';

function useCode2Name() {
    const [nameCN, setNameCN] = useState();
    const [nameEN, setNameEN] = useState();
    const [fetching, setFetching] = useState();
    const abortRef = useRef();

    const fetchName = useCallback(async (code) => {
        abortRef.current = new AbortController();
        setFetching(true);
        try {
            const response = await fetch(`${config.apiURL}/code2name`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${GetToken()}`,
                },
                body: JSON.stringify({ code }),
                signal: abortRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while fetching code2name');
            }
            const data = await response.json();
            // console.log(data);
            const { name, nameEN } = data;
            setNameCN(name);
            setNameEN(nameEN);
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetching code2name:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);

    return { nameCN, nameEN, fetching, fetchName, abort };
}

export default useCode2Name;
