import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AirSearchForm from '@components/air/AirSearchForm';
import { GetAirTicketReq } from '@utils/storage';
import { formatDistanceToNowStrict } from 'date-fns';
import { zhCN } from 'date-fns/locale';
import { Button, Skeleton } from 'antd';
import styles from '@styles/AirTicketPage.module.css';
import TopHeaderBg from '@assets/flight_top_bg.png';
import RecentFlightSearch from '@components/air/RecentFlightSearch';
import RoutingsList from '@components/air/RoutingsList';
import { useFlightSearchContext } from '../../contexts/FlightSearchContext';
import AirFeaturesBanner from './AirFeaturesBanner';
import AirFeatures from './AirFeatures';

function AirTicketPage(props) {
    const [searchReq, setSearchReq] = useState({});
    const { data, timestamp, fetchFlights, abort, fetching, fetched } = useFlightSearchContext();

    useEffect(() => {
        if (Object.keys(searchReq).length === 0) {
            const req = GetAirTicketReq();
            setSearchReq(req);
        }
        return () => {
            if (fetching) abort();
        };
    }, []);

    const handleSubmit = (req) => {
        setSearchReq(req);
        fetchFlights(req);
    };

    const Content = () => {
        if (fetched || fetching || data?.routings?.length > 0) {
            return (
                <>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        {data?.routings?.length > 0 && `${data.routings.length}个结果`}
                        {timestamp &&
                            `(${formatDistanceToNowStrict(new Date(timestamp), { locale: zhCN, addSuffix: true })})`}
                    </div>
                    <RoutingsList routings={data?.routings || []} request={data?.request || {}} />
                    <br />
                </>
            );
        }
        return (
            <section className={styles.features_section}>
                <AirFeaturesBanner />
                <div style={{ marginTop: '32px' }}></div>
                <AirFeatures />
            </section>
        );
    };

    return (
        <div className={styles.container}>
            <section className={styles.top_bg_container}>
                <img src={TopHeaderBg} />
                <div className={styles.works_container}>
                    <div className={styles.paragraph}>随想旅途</div>
                    <div className={styles.paragraph}>
                        全球航线 <span className={styles.colorful}>1000+</span> 条自在飞
                    </div>
                </div>
            </section>

            <section className={styles.content_container}>
                <AirSearchForm onSubmit={handleSubmit} initialData={searchReq} />
                <br />
                <RecentFlightSearch />

                <br />
                <Content />

                {fetching && (
                    <Skeleton
                        active
                        paragraph={{
                            rows: 8,
                        }}
                    />
                )}
            </section>
        </div>
    );
}

AirTicketPage.propTypes = {};

export default React.memo(AirTicketPage);
