import { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { GetToken } from '@utils/storage';
import * as config from '@config';

// Lazy load
function useFlightOrders(props) {
    const [fetching, setFetching] = useState(false);
    const [orders, setOrders] = useState([]);
    //Total number of orders
    const [total, setTotal] = useState(0);
    const abortControllerRef = useRef(null);

    const fetchOrders = useCallback(async ({ page = 1, pageSize = 10 } = {}) => {
        const offset = (page - 1) * pageSize;
        const limit = pageSize;
        setFetching(true);
        abortControllerRef.current = new AbortController();
        const queryParams = {
            limit: limit,
            offset: offset,
        };
        const queryString = new URLSearchParams(queryParams).toString();
        try {
            const response = await fetch(`${config.apiURL2}/flights/orders?${queryString}`, {
                headers: { Authorization: `Bearer ${GetToken()}` },
                signal: abortControllerRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while fetching flight orders');
            }
            const responseData = await response.json();
            const { orders, total } = responseData;
            if (orders) {
                // console.log('orders:', orders);
                setOrders(orders);
            }
            if (total) {
                setTotal(total);
            }
            return responseData;
        } catch (err) {
            if (err.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetching flight orders:', err);
            }
            throw err;
        } finally {
            setFetching(false);
        }
    }, []);

    //Lazy load
    useEffect(() => {
        fetchOrders();
    }, [fetchOrders]);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { orders, total, fetching, refetch: fetchOrders, abort };
}

useFlightOrders.propTypes = {};

export default useFlightOrders;
