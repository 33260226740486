import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography, Space } from 'antd';

const { Text } = Typography;

const RulesModal = ({ rules, open, onClose }) => {
    if (!rules) return null;

    const RuleSection = ({ title, content }) => (
        <Space direction="vertical" size="small">
            <h4>{title}</h4>
            <Text style={{ whiteSpace: 'pre-line' }}>{content}</Text>
        </Space>
    );

    return (
        <Modal title="规则详情" open={open} onCancel={onClose} footer={null} width={600} centered>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <RuleSection title="行李" content={rules.baggage} />
                <RuleSection title="改签" content={rules.change} />
                <RuleSection title="退票" content={rules.refund} />
                <RuleSection title="出票" content={rules.tktgInfo} />
                <RuleSection title="其他" content={rules.other} />
            </Space>
        </Modal>
    );
};

RulesModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    rules: PropTypes.object.isRequired,
};

export default RulesModal;
