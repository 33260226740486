import React from 'react';
import FlightOrdersTable from '../../components/air/FlightOrdersTable';
import { Alert } from 'antd';
import { TicketingMsg } from '@utils/constants';

function AirOrdersPage() {
    return (
        <div>
            <h3>FIT订单</h3>
            <Alert message={TicketingMsg} type="warning" showIcon />
            <br />
            <FlightOrdersTable />
        </div>
    );
}

export default AirOrdersPage;
