export const CabinClass = {
    Economy: 'E',
    Business: 'B',
};

export const australianAirports = [
    {
        cityName: '悉尼',
        cityEnglishName: 'Sydney',
        cityCode: 'SYD',
        theAirportName: '悉尼金斯福德机场',
        airportCode: 'SYD',
        countryName: '澳大利亚',
        countryCode: 'AU',
        value: 'SYD',
    },
    {
        cityName: '墨尔本',
        cityEnglishName: 'Melbourne',
        cityCode: 'MEL',
        theAirportName: '墨尔本机场',
        airportCode: 'MEL',
        countryName: '澳大利亚',
        countryCode: 'AU',
        value: 'MEL',
    },
    {
        cityName: '墨尔本',
        cityEnglishName: 'Melbourne',
        cityCode: 'MEL',
        theAirportName: '阿瓦伦机场',
        airportCode: 'AVV',
        countryName: '澳大利亚',
        countryCode: 'AU',
        value: 'AVV',
    },
    {
        cityName: '布里斯班',
        cityEnglishName: 'Brisbane',
        cityCode: 'BNE',
        theAirportName: '布里斯班机场',
        airportCode: 'BNE',
        countryName: '澳大利亚',
        countryCode: 'AU',
        value: 'BNE',
    },
    {
        cityName: '黄金海岸',
        cityEnglishName: 'Gold Coast',
        cityCode: 'OOL',
        theAirportName: '黄金海岸机场',
        airportCode: 'OOL',
        countryName: '澳大利亚',
        countryCode: 'AU',
        value: 'OOL',
    },
    {
        cityName: '凯恩斯',
        cityEnglishName: 'Cairns',
        cityCode: 'CNS',
        theAirportName: '凯恩斯机场',
        airportCode: 'CNS',
        countryName: '澳大利亚',
        countryCode: 'AU',
        value: 'CNS',
    },
    {
        cityName: '阿德莱德',
        cityEnglishName: 'Adelaide',
        cityCode: 'ADL',
        theAirportName: '阿德莱德机场',
        airportCode: 'ADL',
        countryName: '澳大利亚',
        countryCode: 'AU',
        value: 'ADL',
    },
    {
        cityName: '珀斯',
        cityEnglishName: 'Perth',
        cityCode: 'PER',
        theAirportName: '珀斯机场',
        airportCode: 'PER',
        countryName: '澳大利亚',
        countryCode: 'AU',
        value: 'PER',
    },
];

export const airlines = [
    {
        value: 'QF',
        label: 'QF',
        name: 'Qantas',
        carrierCode: 'QF',
    },
    {
        value: 'VA',
        label: 'VA',
        name: 'Virgin Australia',
        carrierCode: 'VA',
    },
    {
        value: 'JQ',
        label: 'JQ',
        name: 'Jetstar',
        carrierCode: 'JQ',
    },
    {
        value: 'NZ',
        label: 'NZ',
        name: 'Air New Zealand',
        carrierCode: 'NZ',
    },
];
