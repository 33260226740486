export const countryOptions = [
    {
        key: 45,
        text: '中国',
        texten: 'China',
        value: 'CN',
    },
    {
        key: 14,
        text: '澳大利亚',
        texten: 'Australia',
        value: 'AU',
    },
    {
        key: 158,
        text: '新西兰',
        texten: 'New Zealand',
        value: 'NZ',
    },
    {
        key: 1,
        text: '阿富汗',
        texten: 'Afghanistan',
        value: 'AF',
    },
    {
        key: 2,
        text: '奥兰群岛',
        texten: 'Aland Islands',
        value: 'AX',
    },
    {
        key: 3,
        text: '阿尔巴尼亚',
        texten: 'Albania',
        value: 'AL',
    },
    {
        key: 4,
        text: '阿尔及利亚',
        texten: 'Algeria',
        value: 'DZ',
    },
    {
        key: 5,
        text: '美属萨摩亚',
        texten: 'American Samoa',
        value: 'AS',
    },
    {
        key: 6,
        text: '安道尔',
        texten: 'Andorra',

        value: 'AD',
    },
    {
        key: 7,
        text: '安哥拉',
        texten: 'Angola',

        value: 'AO',
    },
    {
        key: 8,
        text: '安圭拉',
        texten: 'Anguilla',
        value: 'AI',
    },
    {
        key: 9,
        text: '南极洲',
        texten: 'Antarctica',
        value: 'AQ',
    },
    {
        key: 10,
        text: '安提瓜和巴布达',
        texten: 'Antigua and Barbuda',
        value: 'AG',
    },
    {
        key: 11,
        text: '阿根廷',
        texten: 'Argentina',

        value: 'AR',
    },
    {
        key: 12,
        text: '亚美尼亚',
        texten: 'Armenia',

        value: 'AM',
    },
    {
        key: 13,
        text: '阿鲁巴',
        texten: 'Aruba',
        value: 'AW',
    },

    {
        key: 15,
        text: '奥地利',
        texten: 'Austria',

        value: 'AT',
    },
    {
        key: 16,
        text: '阿塞拜疆',
        texten: 'Azerbaijan',

        value: 'AZ',
    },
    {
        key: 17,
        text: '巴哈马',
        texten: 'Bahamas (The)',

        value: 'BS',
    },
    {
        key: 18,
        text: '巴林',
        texten: 'Bahrain',

        value: 'BH',
    },
    {
        key: 19,
        text: '孟加拉国',
        texten: 'Bangladesh',

        value: 'BD',
    },
    {
        key: 20,
        text: '巴巴多斯',
        texten: 'Barbados',
        value: 'BB',
    },
    {
        key: 21,
        text: '白俄罗斯',
        texten: 'Belarus',

        value: 'BY',
    },
    {
        key: 22,
        text: '比利时',
        texten: 'Belgium',

        value: 'BE',
    },
    {
        key: 23,
        text: '伯利兹',
        texten: 'Belize',
        value: 'BZ',
    },
    {
        key: 24,
        text: '贝宁',
        texten: 'Benin',

        value: 'BJ',
    },
    {
        key: 25,
        text: '百慕大',
        texten: 'Bermuda',
        value: 'BM',
    },
    {
        key: 26,
        text: '不丹',
        texten: 'Bhutan',

        value: 'BT',
    },
    {
        key: 27,
        text: '玻利维亚',
        texten: 'Bolivia',

        value: 'BO',
    },
    {
        key: 28,
        text: '波黑',
        texten: 'Bosnia and Herzegovina',
        value: 'BA',
    },
    {
        key: 29,
        text: '博茨瓦纳',
        texten: 'Botswana',

        value: 'BW',
    },
    {
        key: 30,
        text: '布维岛',
        texten: 'Bouvet Island',
        value: 'BV',
    },
    {
        key: 31,
        text: '巴西',
        texten: 'Brazil',

        value: 'BR',
    },
    {
        key: 32,
        text: '英属印度洋领地',
        texten: 'British Indian Ocean Territory (the)',
        value: 'IO',
    },
    {
        key: 33,
        text: '文莱',
        texten: 'Brunei Darussalam',
        value: 'BN',
    },
    {
        key: 34,
        text: '保加利亚',
        texten: 'Bulgaria',

        value: 'BG',
    },
    {
        key: 35,
        text: '布基纳法索',
        texten: 'Burkina Faso',

        value: 'BF',
    },
    {
        key: 36,
        text: '布隆迪',
        texten: 'Burundi',

        value: 'BI',
    },
    {
        key: 37,
        text: '柬埔寨',
        texten: 'Cambodia',

        value: 'KH',
    },
    {
        key: 38,
        text: '喀麦隆',
        texten: 'Cameroon',

        value: 'CM',
    },
    {
        key: 39,
        text: '加拿大',
        texten: 'Canada',
        value: 'CA',
    },
    {
        key: 40,
        text: '佛得角',
        texten: 'Cape Verde',

        value: 'CV',
    },
    {
        key: 41,
        text: '开曼群岛',
        texten: 'Cayman Islands (the)',
        value: 'KY',
    },
    {
        key: 42,
        text: '中非',
        texten: 'Central African Republic (the)',

        value: 'CF',
    },
    {
        key: 43,
        text: '乍得',
        texten: 'Chad',

        value: 'TD',
    },
    {
        key: 44,
        text: '智利',
        texten: 'Chile',

        value: 'CL',
    },

    {
        key: 46,
        text: '圣诞岛',
        texten: 'Christmas Island',
        value: 'CX',
    },
    {
        key: 47,
        text: '科科斯（基林）群岛',
        texten: 'Cocos (Keeling) Islands (the)',
        value: 'CC',
    },
    {
        key: 48,
        text: '哥伦比亚',
        texten: 'Colombia',

        value: 'CO',
    },
    {
        key: 49,
        text: '科摩罗',
        texten: 'Comoros',

        value: 'KM',
    },
    {
        key: 50,
        text: '刚果（布）',
        texten: 'Congo',

        value: 'CG',
    },
    {
        key: 51,
        text: '刚果（金）',
        texten: 'Congo (the Democratic Republic of the)',

        value: 'CD',
    },
    {
        key: 52,
        text: '库克群岛',
        texten: 'Cook Islands (the)',
        value: 'CK',
    },
    {
        key: 53,
        text: '哥斯达黎加',
        texten: 'Costa Rica',

        value: 'CR',
    },
    {
        key: 54,
        text: '科特迪瓦',
        texten: "Côte d'Ivoire",

        value: 'CI',
    },
    {
        key: 55,
        text: '克罗地亚',
        texten: 'Croatia',

        value: 'HR',
    },
    {
        key: 56,
        text: '古巴',
        texten: 'Cuba',

        value: 'CU',
    },
    {
        key: 57,
        text: '塞浦路斯',
        texten: 'Cyprus',

        value: 'CY',
    },
    {
        key: 58,
        text: '捷克',
        texten: 'Czech Republic (the)',

        value: 'CZ',
    },
    {
        key: 59,
        text: '丹麦',
        texten: 'Denmark',

        value: 'DK',
    },
    {
        key: 60,
        text: '吉布提',
        texten: 'Djibouti',

        value: 'DJ',
    },
    {
        key: 61,
        text: '多米尼克',
        texten: 'Dominica',

        value: 'DM',
    },
    {
        key: 62,
        text: '多米尼加',
        texten: 'Dominican Republic (the)',

        value: 'DO',
    },
    {
        key: 63,
        text: '厄瓜多尔',
        texten: 'Ecuador',

        value: 'EC',
    },
    {
        key: 64,
        text: '埃及',
        texten: 'Egypt',

        value: 'EG',
    },
    {
        key: 65,
        text: '萨尔瓦多',
        texten: 'El Salvador',

        value: 'SV',
    },
    {
        key: 66,
        text: '赤道几内亚',
        texten: 'Equatorial Guinea',

        value: 'GQ',
    },
    {
        key: 67,
        text: '厄立特里亚',
        texten: 'Eritrea',
        value: 'ER',
    },
    {
        key: 68,
        text: '爱沙尼亚',
        texten: 'Estonia',

        value: 'EE',
    },
    {
        key: 69,
        text: '埃塞俄比亚',
        texten: 'Ethiopia',

        value: 'ET',
    },
    {
        key: 70,
        text: '福克兰群岛（马尔维纳斯）',
        texten: 'Falkland Islands (the) [Malvinas]',
        value: 'FK',
    },
    {
        key: 71,
        text: '法罗群岛',
        texten: 'Faroe Islands (the)',
        value: 'FO',
    },
    {
        key: 72,
        text: '斐济',
        texten: 'Fiji',

        value: 'FJ',
    },
    {
        key: 73,
        text: '芬兰',
        texten: 'Finland',

        value: 'FI',
    },
    {
        key: 74,
        text: '法国',
        texten: 'France',

        value: 'FR',
    },
    {
        key: 75,
        text: '法属圭亚那',
        texten: 'French Guiana',
        value: 'GF',
    },
    {
        key: 76,
        text: '法属波利尼西亚',
        texten: 'French Polynesia',
        value: 'PF',
    },
    {
        key: 77,
        text: '法属南部领地',
        texten: 'French Southern Territories (the)',
        value: 'TF',
    },
    {
        key: 78,
        text: '加蓬',
        texten: 'Gabon',

        value: 'GA',
    },
    {
        key: 79,
        text: '冈比亚',
        texten: 'Gambia (The)',

        value: 'GM',
    },
    {
        key: 80,
        text: '格鲁吉亚',
        texten: 'Georgia',
        value: 'GE',
    },
    {
        key: 81,
        text: '德国',
        texten: 'Germany',

        value: 'DE',
    },
    {
        key: 82,
        text: '加纳',
        texten: 'Ghana',

        value: 'GH',
    },
    {
        key: 83,
        text: '直布罗陀',
        texten: 'Gibraltar',
        value: 'GI',
    },
    {
        key: 84,
        text: '希腊',
        texten: 'Greece',

        value: 'GR',
    },
    {
        key: 85,
        text: '格陵兰',
        texten: 'Greenland',
        value: 'GL',
    },
    {
        key: 86,
        text: '格林纳达',
        texten: 'Grenada',
        value: 'GD',
    },
    {
        key: 87,
        text: '瓜德罗普',
        texten: 'Guadeloupe',
        value: 'GP',
    },
    {
        key: 88,
        text: '关岛',
        texten: 'Guam',
        value: 'GU',
    },
    {
        key: 89,
        text: '危地马拉',
        texten: 'Guatemala',

        value: 'GT',
    },
    {
        key: 90,
        text: '格恩西岛',
        texten: 'Guernsey',
        value: 'GG',
    },
    {
        key: 91,
        text: '几内亚',
        texten: 'Guinea',

        value: 'GN',
    },
    {
        key: 92,
        text: '几内亚比绍',
        texten: 'Guinea-Bissau',

        value: 'GW',
    },
    {
        key: 93,
        text: '圭亚那',
        texten: 'Guyana',

        value: 'GY',
    },
    {
        key: 94,
        text: '海地',
        texten: 'Haiti',

        value: 'HT',
    },
    {
        key: 95,
        text: '赫德岛和麦克唐纳岛',
        texten: 'Heard Island and McDonald Islands',
        value: 'HM',
    },
    {
        key: 96,
        text: '梵蒂冈',
        texten: 'Holy See (the) [Vatican City State]',
        value: 'VA',
    },
    {
        key: 97,
        text: '洪都拉斯',
        texten: 'Honduras',

        value: 'HN',
    },
    {
        key: 98,
        text: '香港',
        texten: 'Hong Kong',

        value: 'HK',
    },
    {
        key: 99,
        text: '匈牙利',
        texten: 'Hungary',

        value: 'HU',
    },
    {
        key: 100,
        text: '冰岛',
        texten: 'Iceland',

        value: 'IS',
    },
    {
        key: 101,
        text: '印度',
        texten: 'India',

        value: 'IN',
    },
    {
        key: 102,
        text: '印度尼西亚',
        texten: 'Indonesia',

        value: 'ID',
    },
    {
        key: 103,
        text: '伊朗',
        texten: 'Iran (the Islamic Republic of)',

        value: 'IR',
    },
    {
        key: 104,
        text: '伊拉克',
        texten: 'Iraq',

        value: 'IQ',
    },
    {
        key: 105,
        text: '爱尔兰',
        texten: 'Ireland',
        value: 'IE',
    },
    {
        key: 106,
        text: '英国属地曼岛',
        texten: 'Isle of Man',
        value: 'IM',
    },
    {
        key: 107,
        text: '以色列',
        texten: 'Israel',

        value: 'IL',
    },
    {
        key: 108,
        text: '意大利',
        texten: 'Italy',

        value: 'IT',
    },
    {
        key: 109,
        text: '牙买加',
        texten: 'Jamaica',
        value: 'JM',
    },
    {
        key: 110,
        text: '日本',
        texten: 'Japan',
        value: 'JP',
    },
    {
        key: 111,
        text: '泽西岛',
        texten: 'Jersey',
        value: 'JE',
    },
    {
        key: 112,
        text: '约旦',
        texten: 'Jordan',

        value: 'JO',
    },
    {
        key: 113,
        text: '哈萨克斯坦',
        texten: 'Kazakhstan',

        value: 'KZ',
    },
    {
        key: 114,
        text: '肯尼亚',
        texten: 'Kenya',

        value: 'KE',
    },
    {
        key: 115,
        text: '基里巴斯',
        texten: 'Kiribati',

        value: 'KI',
    },
    {
        key: 116,
        text: '朝鲜',
        texten: "Korea (the Democratic People's Republic of)",

        value: 'KP',
    },
    {
        key: 117,
        text: '韩国',
        texten: 'Korea (the Republic of)',

        value: 'KR',
    },
    {
        key: 118,
        text: '科威特',
        texten: 'Kuwait',

        value: 'KW',
    },
    {
        key: 119,
        text: '吉尔吉斯斯坦',
        texten: 'Kyrgyzstan',

        value: 'KG',
    },
    {
        key: 120,
        text: '老挝',
        texten: "Lao People's Democratic Republic (the)",

        value: 'LA',
    },
    {
        key: 121,
        text: '拉脱维亚',
        texten: 'Latvia',

        value: 'LV',
    },
    {
        key: 122,
        text: '黎巴嫩',
        texten: 'Lebanon',

        value: 'LB',
    },
    {
        key: 123,
        text: '莱索托',
        texten: 'Lesotho',

        value: 'LS',
    },
    {
        key: 124,
        text: '利比里亚',
        texten: 'Liberia',

        value: 'LR',
    },
    {
        key: 125,
        text: '利比亚',
        texten: 'Libyan Arab Jamahiriya (the)',

        value: 'LY',
    },
    {
        key: 126,
        text: '列支敦士登',
        texten: 'Liechtenstein',

        value: 'LI',
    },
    {
        key: 127,
        text: '立陶宛',
        texten: 'Lithuania',

        value: 'LT',
    },
    {
        key: 128,
        text: '卢森堡',
        texten: 'Luxembourg',

        value: 'LU',
    },
    {
        key: 129,
        text: '澳门',
        texten: 'Macao',

        value: 'MO',
    },
    {
        key: 130,
        text: '前南马其顿',
        texten: 'Macedonia (the former Yugoslav Republic of)',

        value: 'MK',
    },
    {
        key: 131,
        text: '马达加斯加',
        texten: 'Madagascar',

        value: 'MG',
    },
    {
        key: 132,
        text: '马拉维',
        texten: 'Malawi',

        value: 'MW',
    },
    {
        key: 133,
        text: '马来西亚',
        texten: 'Malaysia',
        value: 'MY',
    },
    {
        key: 134,
        text: '马尔代夫',
        texten: 'Maldives',

        value: 'MV',
    },
    {
        key: 135,
        text: '马里',
        texten: 'Mali',

        value: 'ML',
    },
    {
        key: 136,
        text: '马耳他',
        texten: 'Malta',

        value: 'MT',
    },
    {
        key: 137,
        text: '马绍尔群岛',
        texten: 'Marshall Islands (the)',

        value: 'MH',
    },
    {
        key: 138,
        text: '马提尼克',
        texten: 'Martinique',
        value: 'MQ',
    },
    {
        key: 139,
        text: '毛利塔尼亚',
        texten: 'Mauritania',

        value: 'MR',
    },
    {
        key: 140,
        text: '毛里求斯',
        texten: 'Mauritius',

        value: 'MU',
    },
    {
        key: 141,
        text: '马约特',
        texten: 'Mayotte',
        value: 'YT',
    },
    {
        key: 142,
        text: '墨西哥',
        texten: 'Mexico',

        value: 'MX',
    },
    {
        key: 143,
        text: '密克罗尼西亚联邦',
        texten: 'Micronesia (the Federated States of)',

        value: 'FM',
    },
    {
        key: 144,
        text: '摩尔多瓦',
        texten: 'Moldova (the Republic of)',

        value: 'MD',
    },
    {
        key: 145,
        text: '摩纳哥',
        texten: 'Monaco',

        value: 'MC',
    },
    {
        key: 146,
        text: '蒙古',
        texten: 'Mongolia',
        value: 'MN',
    },
    {
        key: 147,
        text: '黑山',
        texten: 'Montenegro',

        value: 'ME',
    },
    {
        key: 148,
        text: '蒙特塞拉特',
        texten: 'Montserrat',
        value: 'MS',
    },
    {
        key: 149,
        text: '摩洛哥',
        texten: 'Morocco',

        value: 'MA',
    },
    {
        key: 150,
        text: '莫桑比克',
        texten: 'Mozambique',

        value: 'MZ',
    },
    {
        key: 151,
        text: '缅甸',
        texten: 'Myanmar',

        value: 'MM',
    },
    {
        key: 152,
        text: '纳米比亚',
        texten: 'Namibia',

        value: 'NA',
    },
    {
        key: 153,
        text: '瑙鲁',
        texten: 'Nauru',

        value: 'NR',
    },
    {
        key: 154,
        text: '尼泊尔',
        texten: 'Nepal',

        value: 'NP',
    },
    {
        key: 155,
        text: '荷兰',
        texten: 'Netherlands (the)',

        value: 'NL',
    },
    {
        key: 156,
        text: '荷属安的列斯',
        texten: 'Netherlands Antilles (the)',
        value: 'AN',
    },
    {
        key: 157,
        text: '新喀里多尼亚',
        texten: 'New Caledonia',
        value: 'NC',
    },

    {
        key: 159,
        text: '尼加拉瓜',
        texten: 'Nicaragua',

        value: 'NI',
    },
    {
        key: 160,
        text: '尼日尔',
        texten: 'Niger (the)',

        value: 'NE',
    },
    {
        key: 161,
        text: '尼日利亚',
        texten: 'Nigeria',

        value: 'NG',
    },
    {
        key: 162,
        text: '纽埃',
        texten: 'Niue',

        value: 'NU',
    },
    {
        key: 163,
        text: '诺福克岛',
        texten: 'Norfolk Island',
        value: 'NF',
    },
    {
        key: 164,
        text: '北马里亚纳',
        texten: 'Northern Mariana Islands (the)',

        value: 'MP',
    },
    {
        key: 165,
        text: '挪威',
        texten: 'Norway',

        value: 'NO',
    },
    {
        key: 166,
        text: '阿曼',
        texten: 'Oman',

        value: 'OM',
    },
    {
        key: 167,
        text: '巴基斯坦',
        texten: 'Pakistan',

        value: 'PK',
    },
    {
        key: 168,
        text: '帕劳',
        texten: 'Palau',

        value: 'PW',
    },
    {
        key: 169,
        text: '巴勒斯坦',
        texten: 'Palestinian Territory (the Occupied)',

        value: 'PS',
    },
    {
        key: 170,
        text: '巴拿马',
        texten: 'Panama',

        value: 'PA',
    },
    {
        key: 171,
        text: '巴布亚新几内亚',
        texten: 'Papua New Guinea',
        value: 'PG',
    },
    {
        key: 172,
        text: '巴拉圭',
        texten: 'Paraguay',

        value: 'PY',
    },
    {
        key: 173,
        text: '秘鲁',
        texten: 'Peru',

        value: 'PE',
    },
    {
        key: 174,
        text: '菲律宾',
        texten: 'Philippines (the)',

        value: 'PH',
    },
    {
        key: 175,
        text: '皮特凯恩',
        texten: 'Pitcairn',
        value: 'PN',
    },
    {
        key: 176,
        text: '波兰',
        texten: 'Poland',

        value: 'PL',
    },
    {
        key: 177,
        text: '葡萄牙',
        texten: 'Portugal',

        value: 'PT',
    },
    {
        key: 178,
        text: '波多黎各',
        texten: 'Puerto Rico',
        value: 'PR',
    },
    {
        key: 179,
        text: '卡塔尔',
        texten: 'Qatar',

        value: 'QA',
    },
    {
        key: 180,
        text: '留尼汪',
        texten: 'Réunion',
        value: 'RE',
    },
    {
        key: 181,
        text: '罗马尼亚',
        texten: 'Romania',
        value: 'RO',
    },
    {
        key: 182,
        text: '俄罗斯联邦',
        texten: 'Russian Federation (the)',

        value: 'RU',
    },
    {
        key: 183,
        text: '卢旺达',
        texten: 'Rwanda',

        value: 'RW',
    },
    {
        key: 184,
        text: '圣赫勒拿',
        texten: 'Saint Helena',
        value: 'SH',
    },
    {
        key: 185,
        text: '圣基茨和尼维斯',
        texten: 'Saint Kitts and Nevis',
        value: 'KN',
    },
    {
        key: 186,
        text: '圣卢西亚',
        texten: 'Saint Lucia',
        value: 'LC',
    },
    {
        key: 187,
        text: '圣皮埃尔和密克隆',
        texten: 'Saint Pierre and Miquelon',
        value: 'PM',
    },
    {
        key: 188,
        text: '圣文森特和格林纳丁斯',
        texten: 'Saint Vincent and the Grenadines',
        value: 'VC',
    },
    {
        key: 189,
        text: '萨摩亚',
        texten: 'Samoa',

        value: 'WS',
    },
    {
        key: 190,
        text: '圣马力诺',
        texten: 'San Marino',

        value: 'SM',
    },
    {
        key: 191,
        text: '圣多美和普林西比',
        texten: 'Sao Tome and Principe',

        value: 'ST',
    },
    {
        key: 192,
        text: '沙特阿拉伯',
        texten: 'Saudi Arabia',

        value: 'SA',
    },
    {
        key: 193,
        text: '塞内加尔',
        texten: 'Senegal',

        value: 'SN',
    },
    {
        key: 194,
        text: '塞尔维亚',
        texten: 'Serbia',

        value: 'RS',
    },
    {
        key: 195,
        text: '塞舌尔',
        texten: 'Seychelles',

        value: 'SC',
    },
    {
        key: 196,
        text: '塞拉利昂',
        texten: 'Sierra Leone',

        value: 'SL',
    },
    {
        key: 197,
        text: '新加坡',
        texten: 'Singapore',

        value: 'SG',
    },
    {
        key: 198,
        text: '斯洛伐克',
        texten: 'Slovakia',

        value: 'SK',
    },
    {
        key: 199,
        text: '斯洛文尼亚',
        texten: 'Slovenia',

        value: 'SI',
    },
    {
        key: 200,
        text: '所罗门群岛',
        texten: 'Solomon Islands (the)',
        value: 'SB',
    },
    {
        key: 201,
        text: '索马里',
        texten: 'Somalia',

        value: 'SO',
    },
    {
        key: 202,
        text: '南非',
        texten: 'South Africa',

        value: 'ZA',
    },
    {
        key: 203,
        text: '南乔治亚岛和南桑德韦奇岛',
        texten: 'South Georgia and the South Sandwich Islands',
        value: 'GS',
    },
    {
        key: 204,
        text: '西班牙',
        texten: 'Spain',

        value: 'ES',
    },
    {
        key: 205,
        text: '斯里兰卡',
        texten: 'Sri Lanka',

        value: 'LK',
    },
    {
        key: 206,
        text: '苏丹',
        texten: 'Sudan (the)',

        value: 'SD',
    },
    {
        key: 207,
        text: '苏里南',
        texten: 'Suriname',

        value: 'SR',
    },
    {
        key: 208,
        text: '斯瓦尔巴岛和扬马延岛',
        texten: 'Svalbard and Jan Mayen',
        value: 'SJ',
    },
    {
        key: 209,
        text: '斯威士兰',
        texten: 'Swaziland',

        value: 'SZ',
    },
    {
        key: 210,
        text: '瑞典',
        texten: 'Sweden',

        value: 'SE',
    },
    {
        key: 211,
        text: '瑞士',
        texten: 'Switzerland',

        value: 'CH',
    },
    {
        key: 212,
        text: '叙利亚',
        texten: 'Syrian Arab Republic (the)',

        value: 'SY',
    },
    {
        key: 213,
        text: '台湾',
        texten: 'Taiwan (Province of China)',
        value: 'TW',
    },
    {
        key: 214,
        text: '塔吉克斯坦',
        texten: 'Tajikistan',

        value: 'TJ',
    },
    {
        key: 215,
        text: '坦桑尼亚',
        texten: 'Tanzania,United Republic of',

        value: 'TZ',
    },
    {
        key: 216,
        text: '泰国',
        texten: 'Thailand',

        value: 'TH',
    },
    {
        key: 217,
        text: '东帝汶',
        texten: 'Timor-Leste',

        value: 'TL',
    },
    {
        key: 218,
        text: '多哥',
        texten: 'Togo',

        value: 'TG',
    },
    {
        key: 219,
        text: '托克劳',
        texten: 'Tokelau',
        value: 'TK',
    },
    {
        key: 220,
        text: '汤加',
        texten: 'Tonga',

        value: 'TO',
    },
    {
        key: 221,
        text: '特立尼达和多巴哥',
        texten: 'Trinidad and Tobago',

        value: 'TT',
    },
    {
        key: 222,
        text: '突尼斯',
        texten: 'Tunisia',

        value: 'TN',
    },
    {
        key: 223,
        text: '土耳其',
        texten: 'Turkey',

        value: 'TR',
    },
    {
        key: 224,
        text: '土库曼斯坦',
        texten: 'Turkmenistan',
        value: 'TM',
    },
    {
        key: 225,
        text: '特克斯和凯科斯群岛',
        texten: 'Turks and Caicos Islands (the)',
        value: 'TC',
    },
    {
        key: 226,
        text: '图瓦卢',
        texten: 'Tuvalu',
        value: 'TV',
    },
    {
        key: 227,
        text: '乌干达',
        texten: 'Uganda',

        value: 'UG',
    },
    {
        key: 228,
        text: '乌克兰',
        texten: 'Ukraine',
        value: 'UA',
    },
    {
        key: 229,
        text: '阿联酋',
        texten: 'United Arab Emirates (the)',

        value: 'AE',
    },
    {
        key: 230,
        text: '英国',
        texten: 'United Kingdom (the)',

        value: 'GB',
    },
    {
        key: 231,
        text: '美国',
        texten: 'United States (the)',

        value: 'US',
    },
    {
        key: 232,
        text: '美国本土外小岛屿',
        texten: 'United States Minor Outlying Islands (the)',
        value: 'UM',
    },
    {
        key: 233,
        text: '乌拉圭',
        texten: 'Uruguay',

        value: 'UY',
    },
    {
        key: 234,
        text: '乌兹别克斯坦',
        texten: 'Uzbekistan',

        value: 'UZ',
    },
    {
        key: 235,
        text: '瓦努阿图',
        texten: 'Vanuatu',

        value: 'VU',
    },
    {
        key: 236,
        text: '委内瑞拉',
        texten: 'Venezuela',

        value: 'VE',
    },
    {
        key: 237,
        text: '越南',
        texten: 'Viet Nam',

        value: 'VN',
    },
    {
        key: 238,
        text: '英属维尔京群岛',
        texten: 'Virgin Islands (British)',

        value: 'VG',
    },
    {
        key: 239,
        text: '美属维尔京群岛',
        texten: 'Virgin Islands (U.S.)',

        value: 'VI',
    },
    {
        key: 240,
        text: '瓦利斯和富图纳',
        texten: 'Wallis and Futuna',

        value: 'WF',
    },
    {
        key: 241,
        text: '西撒哈拉',
        texten: 'Western Sahara',
        value: 'EH',
    },

    {
        key: 242,
        text: '也门',
        texten: 'Yemen',

        value: 'YE',
    },

    {
        key: 243,
        text: '赞比亚',
        texten: 'Zambia',

        value: 'ZM',
    },
    {
        key: 244,
        text: '津巴布韦',
        texten: 'Zimbabwe',

        value: 'ZW',
    },
];
