import React from 'react';
import styles from '@styles/AirFeatures.module.css';
import IconAuth from '@assets/air-features/icon_auth.svg';
import IconCoupon from '@assets/air-features/icon_coupon.svg';
import IconOneStop from '@assets/air-features/icon_one_stop.svg';
import Icon24hours from '@assets/air-features/icon_24_hours.svg';
import IconEfficient from '@assets/air-features/icon_efficient.svg';

function AirFeatures() {
    return (
        <div className={styles.container}>
            {/* 1 */}
            <div className={styles.item_container}>
                <div className={styles.top_container}>
                    <img src={IconAuth} />
                </div>
                <div className={styles.bottom_container}>
                    <div className={styles.title}>权威认证</div>
                    <li className={styles.point}>IATA一级代理资质，值得信赖</li>
                </div>
            </div>
            {/* 2 */}
            <div className={styles.item_container}>
                <div className={styles.top_container}>
                    <img src={IconCoupon} />
                </div>
                <div className={styles.bottom_container}>
                    <div className={styles.title}>超优惠价格</div>
                    <li className={styles.point}>汇集众多航空公司实时优惠票价</li>
                    <li className={styles.point}>确保您获得最具竞争力的价格</li>
                </div>
            </div>
            {/* 3 */}
            <div className={styles.item_container}>
                <div className={styles.top_container}>
                    <img src={IconOneStop} />
                </div>
                <div className={styles.bottom_container}>
                    <div className={styles.title}>一站式服务</div>
                    <li className={styles.point}>从搜索到出票全流程无缝衔接</li>
                    <li className={styles.point}>包含订单修改等售后服务</li>
                    <li className={styles.point}>附加产品轻松预订</li>
                </div>
            </div>
            {/* 4 */}
            <div className={styles.item_container}>
                <div className={styles.top_container}>
                    <img src={Icon24hours} />
                </div>
                <div className={styles.bottom_container}>
                    <div className={styles.title}>全天候支持</div>
                    <li className={styles.point}>24/7/365不间断客服</li>
                    <li className={styles.point}>专业代理实时响应</li>
                </div>
            </div>
            {/* 5 */}
            <div className={styles.item_container}>
                <div className={styles.top_container}>
                    <img src={IconEfficient} />
                </div>
                <div className={styles.bottom_container}>
                    <div className={styles.title}>便捷高效</div>
                    <li className={styles.point}>实时在线采购系统</li>
                    <li className={styles.point}>快速便捷的预订流程</li>
                </div>
            </div>
        </div>
    );
}

export default AirFeatures;
