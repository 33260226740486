import React from 'react';
import { Modal, Table, Typography } from 'antd';
import { timeParse } from '../utils';
import PropTypes from 'prop-types';

const { Title } = Typography;

const FlightDetailsModal = ({ open, routing, onClose }) => {
    if (!routing) return null;

    const isRoundTrip = routing.retSegments && routing.retSegments.length > 0;

    const columns = [
        { title: '序号', dataIndex: 'index', key: 'index' },
        { title: '航司', dataIndex: 'airline', key: 'airline' },
        { title: '航班号', dataIndex: 'flightNumber', key: 'flightNumber' },
        { title: '舱等', dataIndex: 'cabinClass', key: 'cabinClass' },
        { title: '行程', dataIndex: 'route', key: 'route' },
        { title: '时间', dataIndex: 'time', key: 'time' },
        { title: '飞行时长', dataIndex: 'duration', key: 'duration' },
    ];

    const getFlightDetails = (segments) =>
        segments.map((segment, index) => ({
            key: index,
            index: index + 1,
            airline: segment.airline.companyName,
            flightNumber: segment.flightNumber,
            cabinClass: segment.cabinClassName,
            route: `${segment.depAirportName}${segment.depTerminal && `T${segment.depTerminal}`}(${
                segment.depAirportCode
            }) - ${segment.arrAirportName}${segment.arrTerminal && `T${segment.arrTerminal}`}(${
                segment.arrAirportCode
            })`,
            time: `${timeParse(segment.depTime)} - ${timeParse(segment.arrTime)}`,
            duration: `${Math.floor(segment.duration / 60)}h${segment.duration % 60}m`,
        }));

    return (
        <Modal title="航班详情" open={open} onCancel={onClose} footer={null} width={'auto'} centered>
            {/* From */}
            {isRoundTrip && <Title level={5}>去程</Title>}
            <div style={{ overflowX: 'auto' }}>
                <Table columns={columns} dataSource={getFlightDetails(routing.fromSegments)} pagination={false} />
            </div>

            {/* To */}
            {routing.retSegments && routing.retSegments.length > 0 && (
                <>
                    <Title level={5} style={{ marginTop: 20 }}>
                        回程
                    </Title>
                    <Table columns={columns} dataSource={getFlightDetails(routing.retSegments)} pagination={false} />
                </>
            )}
        </Modal>
    );
};

FlightDetailsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    routing: PropTypes.object.isRequired,
};

export default FlightDetailsModal;
