import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './Filters.module.css';
import { Button, Checkbox, Select } from 'antd';
import { STOP_TYPES, TIME_SLOTS } from '../constants.js';
import { ReloadOutlined } from '@ant-design/icons';

export const FilterKey = {
    AIRLINE: 'airline', // airline IATA code
    DEPARTURE_TIME: 'departureTime',
    ARRIVAL_TIME: 'arrivalTime',
    DEPARTURE: 'departure',
    ARRIVAL: 'arrival',
    STOP_TYPE: 'stopType',
    STOPS: 'stops',
    LOWEST_PRICE: 'lowestPrice',
};

function Filters({
    airlineOptions = [],
    stopsOptions = [],
    depAirportOptions = [],
    arrAirportOptions = [],
    filterData = {},
    setFilterData = () => {},
}) {
    const timeOptions = useMemo(
        () => Object.values(TIME_SLOTS).map((slot) => ({ label: slot.label, value: slot.value })),
        []
    );

    const handleFilterChange = useCallback(
        (key, value) => {
            setFilterData((prev) => ({ ...prev, [key]: value }));
        },
        [setFilterData]
    );

    const handleStopTypeChange = useCallback(
        (e) => {
            handleFilterChange(
                FilterKey.STOP_TYPE,
                e.target.checked ? STOP_TYPES.NONSTOP.value : STOP_TYPES.WITH_STOPS.value
            );
        },
        [handleFilterChange]
    );

    const handleReset = () => {
        setFilterData({});
    };

    return (
        <div className={styles.container}>
            <div className={styles.leading_container}>
                <Checkbox
                    checked={filterData[FilterKey.STOP_TYPE] === STOP_TYPES.NONSTOP.value}
                    onChange={handleStopTypeChange}
                >
                    直飞
                </Checkbox>
                <Select
                    placeholder="中转"
                    onChange={(value) => handleFilterChange(FilterKey.STOPS, value)}
                    value={filterData[FilterKey.STOPS]}
                    size="small"
                    options={stopsOptions}
                    disabled={filterData[FilterKey.STOP_TYPE] === STOP_TYPES.NONSTOP.value || stopsOptions?.length == 0}
                    allowClear
                    popupMatchSelectWidth={false}
                />
                <Select
                    placeholder="航空公司"
                    onChange={(value) => handleFilterChange(FilterKey.AIRLINE, value)}
                    value={filterData[FilterKey.AIRLINE]}
                    size="small"
                    options={airlineOptions}
                    allowClear
                    popupMatchSelectWidth={false}
                />
                <Select
                    placeholder="起飞时间"
                    value={filterData[FilterKey.DEPARTURE_TIME]}
                    onChange={(value) => handleFilterChange(FilterKey.DEPARTURE_TIME, value)}
                    size="small"
                    options={timeOptions}
                    popupMatchSelectWidth={false}
                    allowClear
                />
                <Select
                    placeholder="到达时间"
                    value={filterData[FilterKey.ARRIVAL_TIME]}
                    onChange={(value) => handleFilterChange(FilterKey.ARRIVAL_TIME, value)}
                    size="small"
                    options={timeOptions}
                    popupMatchSelectWidth={false}
                    allowClear
                />
                <Select
                    placeholder="出发机场"
                    value={filterData[FilterKey.DEPARTURE]}
                    onChange={(value) => handleFilterChange(FilterKey.DEPARTURE, value)}
                    size="small"
                    options={depAirportOptions}
                    popupMatchSelectWidth={false}
                    allowClear
                />
                <Select
                    placeholder="到达机场"
                    value={filterData[FilterKey.ARRIVAL]}
                    onChange={(value) => handleFilterChange(FilterKey.ARRIVAL, value)}
                    size="small"
                    options={arrAirportOptions}
                    popupMatchSelectWidth={false}
                    allowClear
                />
            </div>
            <div className={styles.trailing_container}>
                <Button type="link" className="color_indictive" onClick={handleReset} style={{ padding: 0 }}>
                    <ReloadOutlined /> 重置
                </Button>
            </div>
        </div>
    );
}

Filters.propTypes = {
    airlineOptions: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
    stopsOptions: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
    depAirportOptions: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
    arrAirportOptions: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
    filterData: PropTypes.object,
    setFilterData: PropTypes.func.isRequired,
};

export default React.memo(Filters);
