import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Tag, Badge } from 'antd';
import dayjs from 'dayjs';
import useGroupEnquiries from './useGroupEnquiries';
import { Link } from 'react-router-dom';
import { GetUserInfo } from '@utils/storage';
import { GetQuery, SaveQuery } from './storage';

export const defaultQueryReq = {
    page: 1,
    pageSize: 10,
};

function GroupTravelEnuiriesTable() {
    const { fetching, fetchEnquiries, enquiries, total, abort } = useGroupEnquiries();
    const [queryReq, setQueryReq] = useState(GetQuery());

    useEffect(() => {
        return () => {
            abort();
        };
    }, []);

    useEffect(() => {
        fetchData(queryReq);
    }, [queryReq]);

    const fetchData = ({ page, pageSize } = {}) => {
        console.log('fetchData', { page, pageSize });

        fetchEnquiries({ page, pageSize })
            .then((result) => {
                //Save query params to session storage
                // console.log('SaveQuery', { page, pageSize });
                SaveQuery({ page, pageSize });
            })
            .catch((error) => {
                console.error('Error fetching enquiries:', error);
            });
    };

    const handleTableChange = (newPagination) => {
        // console.log('newPagination', newPagination);
        setQueryReq((prev) => {
            return { ...prev, page: newPagination.current, pageSize: newPagination.pageSize };
        });
    };

    const columns = [
        {
            title: '团队名称',
            dataIndex: ['enquiry', 'groupName'],
            key: 'groupName',
        },
        {
            title: '提交时间',
            dataIndex: ['enquiry', 'createdAt'],
            key: 'createdAt',
            render: (text, record) => {
                return dayjs(text).format('YYYY-MM-DD HH:mm');
            },
        },
        {
            title: '人数',
            key: 'passengers',
            render: (text, record) => {
                const { numAdult, numChild } = record.enquiry;
                return (
                    <span>
                        {numAdult}成人
                        {numChild > 0 && ` + ${numChild}儿童`}
                    </span>
                );
            },
        },

        {
            title: '航班',
            dataIndex: ['enquiry', 'flightSegments'],
            key: 'flightSegments',
            render: (segments) => (
                <>
                    {segments.map((segment, index) => (
                        <div key={index}>
                            <Tag color="blue" style={{ marginBottom: '4px' }}>
                                {segment.date}
                            </Tag>
                            <Tag color="blue" style={{ marginBottom: '4px' }}>
                                {`${segment.fromAirport} → ${segment.toAirport}`}
                            </Tag>
                            <Tag color="green">{segment.carrierCode}</Tag>
                            <Tag color="green">{segment.flightNumbers.join(', ')}</Tag>
                            <Tag color="green">{segment.cabinClass}</Tag>
                        </div>
                    ))}
                </>
            ),
        },
        {
            title: '状态',
            dataIndex: 'comments',
            key: 'comments',
            render: (comments, record) => {
                const id = record.enquiry.id;
                if (comments && comments.length > 0) {
                    const replied = comments.some((comment) => comment.accountID !== GetUserInfo().id);
                    if (replied) {
                        return <Badge status="success" text="已回复" />;
                    }
                }
                return <Badge status="processing" text="处理中" />;
            },
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => {
                const id = record.enquiry.id;
                return (
                    <Link to={id}>
                        <Button type="link" size="small">
                            查看
                        </Button>
                    </Link>
                );
            },
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={enquiries}
            loading={fetching}
            rowKey={(record) => record.enquiry.id}
            pagination={{
                current: queryReq.page,
                pageSize: queryReq.pageSize,
                total,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ['5', '10', '20', '50'],
            }}
            onChange={handleTableChange}
            bordered
        />
    );
}

GroupTravelEnuiriesTable.propTypes = {};

export default GroupTravelEnuiriesTable;
