import { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useFlightCreateOrder() {
    // submitting
    const [submitting, setSubmitting] = useState(false);
    // abort controller ref
    const abortControllerRef = useRef(null);

    const handleCreateOrder = useCallback(async (req) => {
        setSubmitting(true);
        abortControllerRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL2}/flights/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${GetToken()}`,
                },
                body: JSON.stringify(req),
                signal: abortControllerRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to create order');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error create order:', error);
            }
            throw error;
        } finally {
            setSubmitting(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { submitting, handleCreateOrder, abort };
}

useFlightCreateOrder.propTypes = {};

export default useFlightCreateOrder;
