import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Segment.module.css';
import * as config from '@config';
import { differenceInMinutes, format, parse } from 'date-fns';
import { CabinClassName } from '@components/CabinPicker';
import useCode2Name from '../../../hooks/flight/useCode2Name';
import AirportName from './AirportName';

const getDate = (str) => {
    const dateObj = parse(str, 'yyyyMMddHHmm', new Date());
    // Format the date and time
    return format(dateObj, 'MM-dd');
};

const getTime = (str) => {
    const dateObj = parse(str, 'yyyyMMddHHmm', new Date());
    // Format the date and time
    return format(dateObj, 'HH:mm');
};

export const duration = (depTime, arrTime) => {
    const dep = parse(depTime, 'yyyyMMddHHmm', new Date());
    const arr = parse(arrTime, 'yyyyMMddHHmm', new Date());
    const totalMinutes = differenceInMinutes(arr, dep);
    return `${Math.floor(totalMinutes / 60)}h${totalMinutes % 60}m`;
};

function Segment({ segment = {}, label }) {
    return (
        <div className={styles.container}>
            <div className={styles.top_container}>
                {label && <div className={styles.label_container}>{label}</div>}
                <div className={styles.image_container}>
                    <img src={`${config.baseURL}/images/airlineIcon/${segment.carrier?.toUpperCase()}.svg`} alt="" />
                </div>
                <div className={styles.airline}>{segment.carrier}</div>
                <div className={styles.flight_number}>
                    {segment.flightNumber}
                    {segment.codeShare && `(${segment.sharingFlightNumber})`}
                </div>
            </div>
            <div className={styles.bottom_container}>
                <div className={styles.left_container}>
                    <div className={styles.dep_time}>
                        <div className={styles.date}>{getDate(segment.depTime)}</div>
                        <div className={styles.time}>{getTime(segment.depTime)}</div>
                    </div>
                    <div className={styles.duration}>{duration(segment.depTime, segment.arrTime)}</div>
                    <div className={styles.arr_time}>
                        <div className={styles.date}>{getDate(segment.arrTime)}</div>
                        <div className={styles.time}>{getTime(segment.arrTime)}</div>
                    </div>
                </div>
                <div className={styles.line_container}></div>
                <div className={styles.right_container}>
                    <div className={styles.dep_airport}>
                        <div>{segment.depAirport}</div>
                        <AirportName code={segment.depAirport} />
                        {/* <div>{segment.depTerminal}</div> */}
                    </div>
                    <div className={styles.multi}>
                        <div className={styles.cabin}>
                            {CabinClassName(segment.cabinClass)} {segment.cabin}
                        </div>
                        {!!segment.baggagePieces && (
                            <div className={styles.baggage}>
                                {segment.baggagePieces}PC
                                {segment.baggageWeight && `/${segment.baggageWeight}Kg`}
                            </div>
                        )}
                    </div>

                    <div className={styles.arr_airport}>
                        <div>{segment.arrAirport}</div>
                        <AirportName code={segment.arrAirport} />
                        {/* <div>{segment.arrTerminal}</div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

Segment.propTypes = {
    segment: PropTypes.object.isRequired,
    label: PropTypes.string,
};

export default Segment;
