import { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as config from '@config';
import { GetToken } from '@utils/storage';
import { TripType } from '@components/air/TripTypePicker';

function useFlightVerifyPrice() {
    const [verifying, setVerifying] = useState(false);
    const abortControllerRef = useRef(null);

    const handleVerify = useCallback(
        async ({ request = null, priceInfo = null, fromSegments = [], retSegments = [] }) => {
            if (!request) {
                throw new Error('Request is required');
            }
            if (!priceInfo) {
                throw new Error('Price info is required');
            }
            if (fromSegments.length === 0) {
                throw new Error('At least one fromSegment is required');
            }
            // console.log('request:', request);
            // console.log('priceInfo:', priceInfo);
            for (let i = 0; i < fromSegments.length; i++) {
                fromSegments[i].cabin = priceInfo.flights.from[i].cabin;
            }
            for (let i = 0; i < retSegments.length; i++) {
                retSegments[i].cabin = priceInfo.flights.ret[i].cabin;
            }
            const req = {
                numAdult: request.numAdult,
                numChild: request.numChild,
                tripType: request.tripType,
                routing: {
                    channelSource: priceInfo.channelSource,
                    data: priceInfo.data,
                    fromSegments: fromSegments,
                    retSegments: retSegments,
                },
                creditTest: priceInfo.totalPrice ?? 0, //to test credit
            };
            console.log('verify req:', req);
            setVerifying(true);
            abortControllerRef.current = new AbortController();
            try {
                //DEBUG:
                // await new Promise((resolve) => setTimeout(resolve, 6000));
                // throw new Error('Simulation');
                const response = await fetch(`${config.apiURL2}/flights/verify`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${GetToken()}`,
                    },
                    body: JSON.stringify(req),
                    signal: abortControllerRef.current.signal,
                });
                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(data.error || 'An error occurred while verifying flight price');
                }
                const data = await response.json();
                console.log(data);
                return data;
            } catch (err) {
                if (err.name === 'AbortError') {
                    console.log('Request was aborted');
                    throw new Error('Request was aborted');
                } else {
                    console.error('Error verifying flight price:', err);
                    throw new Error(err.message || 'An error occurred while verifying flight price');
                }
            } finally {
                setVerifying(false);
            }
        },
        [config.apiURL2]
    );

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { verifying, handleVerify, abort };
}

useFlightVerifyPrice.propTypes = {};

export default useFlightVerifyPrice;
