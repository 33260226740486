import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import Segment, { duration } from './Segment';
import { ClockCircleOutlined } from '@ant-design/icons';
import AirportName from './AirportName';

function FlightSegments({ fromSegments = [], retSegments = [] }) {
    const isRoundTrip = retSegments?.length > 0;

    const Segments = ({ segments, label }) => {
        return (
            <>
                {segments.map((segment, index) => {
                    return (
                        <>
                            {index > 0 && (
                                <div key={'stop' + index} className={styles.stop_container}>
                                    <ClockCircleOutlined /> <span>中转</span>
                                    <span className={styles.hightlight}>{fromSegments[index - 1].arrAirport}</span>
                                    <span className={styles.hightlight}>
                                        <AirportName code={fromSegments[index - 1].arrAirport} />
                                    </span>
                                    <span className={styles.duration}>
                                        {duration(fromSegments[index - 1].arrTime, fromSegments[index].depTime)}
                                    </span>
                                </div>
                            )}
                            <Segment key={'seg' + index} segment={segment} label={index == 0 && label} />
                        </>
                    );
                })}
            </>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.from_segments_container}>
                <Segments segments={fromSegments} label={isRoundTrip && '去程'} />
            </div>
            {isRoundTrip && (
                <div className={styles.ret_segments_container}>
                    <Segments segments={retSegments} label={isRoundTrip && '返程'} />
                </div>
            )}
        </div>
    );
}

FlightSegments.propTypes = {
    fromSegments: PropTypes.array.isRequired,
    retSegments: PropTypes.array,
};

export default React.memo(FlightSegments);
