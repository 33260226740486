import React from 'react';
import RoutingsList from './RoutingsList';
import PropTypes from 'prop-types';

function index({ routings = [], request = {} }) {
    return <RoutingsList routings={routings} request={request} />;
}

index.propTypes = {
    routings: PropTypes.array.isRequired,
    request: PropTypes.object.isRequired,
};

export default React.memo(index);
