import { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useGroupEnquiryUpdate(props) {
    const [submitting, setSubmitting] = useState(false);
    const abortControllerRef = useRef(null);

    const handleEnquiryUpdate = useCallback(async (id, req) => {
        abortControllerRef.current = new AbortController();
        try {
            setSubmitting(true);
            const response = await fetch(`${config.apiURL2}/group-travel/enquiries/${id}`, {
                method: 'PATCH',
                body: JSON.stringify(req),
                headers: { Authorization: `Bearer ${GetToken()}` },
                signal: abortControllerRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while update a group enquiry');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error update a group enquiry:', error);
            }
            throw error;
        } finally {
            setSubmitting(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { submitting, handleEnquiryUpdate, abort };
}

useGroupEnquiryUpdate.propTypes = {};

export default useGroupEnquiryUpdate;
