import { useCallback, useState, useRef } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

export const IssueType = {
    Issue: 1,
    Cancel: 2,
};

function useFlightIssue() {
    const [submitting, setSubmitting] = useState(false);
    const [data, setData] = useState();
    const abortRef = useRef();

    const handleIssue = useCallback(async (req) => {
        setSubmitting(true);
        try {
            console.log(req);
            abortRef.current = new AbortController();
            const response = await fetch(`${config.apiURL2}/flights/issue`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${GetToken()}`,
                },
                body: JSON.stringify(req),
                signal: abortRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while issuing flight');
            }
            const data = await response.json();
            setData(data);
            return data;
        } catch (err) {
            if (err.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error issuing flight:', err);
            }
            throw err;
        } finally {
            setSubmitting(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);

    return { submitting, data, handleIssue, abort };
}

export default useFlightIssue;
