import React from 'react';
import { Route, Routes } from 'react-router';
import AirTicketPage from './AirTicketPage';
import AirBookingPage from './AirBookingPage';
import AirOrdersPage from './AirOrdersPage';
import GroupTravelPage from './GroupTravelPage';
import GroupEnquiriesPage from './GroupEnquiriesPage';
import { Result } from 'antd';
import GroupEnquiryPage from './GroupEnquiryPage';
import { FlightSearchProvider } from '../../contexts/FlightSearchContext';
import FlightOrderPage from './FlightOrderPage';

function Index() {
    return (
        <Routes>
            <Route path="/">
                <Route path="git/*">
                    <Route index exact element={<GroupTravelPage />} />
                    <Route path="enquiries" exact element={<GroupEnquiriesPage />} />
                    <Route path="enquiries/:id" exact element={<GroupEnquiryPage />} />
                </Route>
                <Route path="fit/*">
                    <Route
                        index
                        element={
                            <FlightSearchProvider>
                                <AirTicketPage />
                            </FlightSearchProvider>
                        }
                    />
                    <Route path="orders" exact element={<AirOrdersPage />} />
                    <Route path="orders/:id" exact element={<FlightOrderPage />} />
                    <Route path="book" exact element={<AirBookingPage />} />
                </Route>
                <Route
                    path="*"
                    element={<Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />}
                />
            </Route>
        </Routes>
    );
}

export default Index;
